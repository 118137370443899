<template>
  <div class="home">
    <v-container>
      <v-row>
        <v-col>
          <v-img
            class="mt-10"
            :src="require('../assets/images/deer-logo.svg')"
            contain
            height="300"
          />
        </v-col>
      </v-row>
      <lottie-player
        class="astronaut hidden-sm-and-down"
        src="/json/astronaut.json"
        background="transparent"
        speed="1"
        loop
        autoplay
      ></lottie-player>
    </v-container>
  </div>
</template>

<style scoped>
.home {
  position: relative;
  height: 100%;
  background-image: url('../assets/images/space-bg.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.astronaut {
  position: absolute;
  right: 3em;
  bottom: 3em;
  height: 500px;
  width: 500px;
}

@media only screen and (max-width: 1264px) {
  .astronaut {
    right: 3em;
    bottom: 3em;
    height: 300px;
    width: 300px;
  }
}
</style>

<script>
export default {
  name: 'Home',
  data() {
    return {
    };
  },
};
</script>
